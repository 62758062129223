import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import { NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import { bgBlur } from '../../../utils/cssStyles';
// ----------------------------------------------------------------------
export default function NavToggleButton({ sx, ...other }) {
    const theme = useTheme();
    const { themeLayout, onToggleLayout } = useSettingsContext();
    const isDesktop = useResponsive('up', 'lg');
    if (!isDesktop) {
        return null;
    }
    return (_jsx(IconButton, { size: "small", onClick: onToggleLayout, sx: {
            p: 0.5,
            top: 32,
            position: 'fixed',
            left: NAV.W_DASHBOARD - 12,
            zIndex: theme.zIndex.appBar + 1,
            border: `dashed 1px ${theme.palette.divider}`,
            ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
            '&:hover': {
                bgcolor: 'background.default',
            },
            ...sx,
        }, ...other, children: _jsx(Iconify, { width: 16, icon: themeLayout === 'vertical' ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill' }) }));
}
